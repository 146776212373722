<template>
  <table class="members-list" v-if="list !== null && list.length !== 0">
    <thead>
      <tr>
        <th class="checkbox"><p>#</p></th>
        <th class="username"><p>{{ t('components.membersList.username') }}</p></th>
        <th class="nickname"><p>{{ t('components.membersList.nickname') }}</p></th>
        <th class="total" @click="orderBy('total')"><p :class="order === 'total' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.membersList.totalMessage') }}</p></th>
        <th class="time" @click="orderBy('jtime')"><p :class="order === 'jtime' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.membersList.joinTime') }}</p></th>
        <th class="time" @click="orderBy('lmtime')"><p :class="order === 'lmtime' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.membersList.latestMsgTime') }}</p></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in list" :key="index">
        <td class="checkbox">
          <div v-if="!/creator|administrator/.test(item.level)">
            <input :id="`member-${index}`" type="checkbox" :name="item.nickname" :value="item.id" v-model="item.checkbox" @change="selectOne()">
            <label :for="`member-${index}`"></label>
          </div>
          <div v-else class="star">
            <i v-if="item.level === 'creator'" class="star-blue"></i>
            <i v-else-if="item.level === 'administrator'" class="star-gray"></i>
          </div>
        </td>
        <td :title="item.username"><p>{{ item.username }}</p></td>
        <td :title="item.nickname">
          <p class="nickname-td" @click="goMember(item.id)" :style="{ cursor: record ? 'pointer' : 'default' }">
            <span class="avatar" :style="{backgroundImage: `url(${item.avatar})`}"></span>
            <span class="name" :style="{color: item.level === 'left' ? '#ff2c2c' : ''}">{{ item.nickname }}</span>
          </p>
        </td>
        <td :title="item.total"><p>{{ item.total }}</p></td>
        <td :title="item.joinTime"><p>{{ item.joinTime }}</p></td>
        <td :title="item.latestMsgTime"><p>{{ item.latestMsgTime }}</p></td>
      </tr>
      <tr class="action-bar">
        <td colspan="7">
          <div class="operate">
            <div class="select-all">
              <div>
                <input id="select-all" type="checkbox" v-model="selectAll" :indeterminate="selectAllHalf">
                <label for="select-all">{{ t('components.membersList.selectAll') }}</label>
              </div>
            </div>
            <div class="pagination">
              <div class="page">
                <a href="javascript:void(0)" class="left" @click="pagePrev()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" xml:space="preserve">
                    <path d="M220.9,513.4c0,17.7,6.6,33.7,17.7,45.8l4.7,4.7l45.8,45.8l395.6,395.6c26.4,26.4,69.9,26.4,96.5,0 c26.4-26.4,26.4-69.9,0-96.5L385.3,513.2l395.6-395.8c26.4-26.4,26.4-69.9,0-96.5C754.5-5.5,711-5.5,684.6,21.1L289,416.7 l-46.4,46.4l-3.8,3.8C227.5,479.1,220.7,495.5,220.9,513.4L220.9,513.4z"/>
                  </svg>
                </a>
                <span>
                  <em v-for="(item, index) in pagination" :key="index" :style="{cursor: isNaN(item) ? '' : 'pointer'}" @click="page(item)"><i :style="{ background: offset === item - 1 ? '#e54d26' : '#60b7ff' }">{{ item }}</i></em>
                </span>
                <a href="javascript:void(0)" class="right" @click="pageNext()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" xml:space="preserve">
                    <path d="M801,512.8c0-17.7-6.6-33.7-17.7-45.8l-4.7-4.7l-45.8-45.8L337.2,20.9c-26.4-26.4-69.9-26.4-96.5,0 c-26.4,26.4-26.4,69.9,0,96.5L636.6,513L241,908.8c-26.4,26.4-26.4,69.9,0,96.5c26.4,26.4,69.9,26.4,96.3-0.2l395.6-395.6l46.4-46.4 l3.8-3.8C794.4,547.1,801.2,530.7,801,512.8L801,512.8z"/>
                  </svg>
                </a>
              </div>
              <div class="total">
                <span>{{ t('components.membersList.totalLine', { total: total }) }}</span>
              </div>
              <div class="limit">
                <select v-model="limit">
                  <option value="10">{{ t('components.membersList.option.ten') }}</option>
                  <option value="20">{{ t('components.membersList.option.twenty') }}</option>
                  <option value="30">{{ t('components.membersList.option.thirty') }}</option>
                  <option value="50">{{ t('components.membersList.option.fifty') }}</option>
                  <option value="70">{{ t('components.membersList.option.seventy') }}</option>
                  <option value="100">{{ t('components.membersList.option.hundred') }}</option>
                </select>
              </div>
            </div>
            <div class="radio">
              <div>
                <input type="radio" id="blocked-no" value="false" v-model="block">
                <label for="blocked-no">{{ t('components.membersList.notAddBlockList') }}</label>
              </div>
              <div>
                <input type="radio" id="blocked-yes" value="true" v-model="block">
                <label for="blocked-yes">{{ t('components.membersList.addBlockList') }}</label>
              </div>
            </div>
            <div class="submit">
              <a href="javascript:void(0)" @click="kickMembers()">{{ t('components.membersList.kickSelectedMember') }}</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="no-data" v-if="list !== null && list.length === 0">{{ t('components.noData') }}</p>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useDialog } from '../utils/dialog.js'
import { kickMembersApi } from '../api/index.js'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, reactive, toRefs, watch } from 'vue'

export default {
  name: 'MembersList',
  props: {
    total: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => { return [] }
    }
  },
  emits: [
    'reload'
  ],
  setup(props, context) {
    const { t } = useI18n()
    const Store = useStore()
    const Route = useRoute()
    const Router = useRouter()
    const Dialog = useDialog()
    const data = reactive({
      record: computed(() => Store.getters.get('record')),

      sort: 'desc',
      order: 'total',
      limit: 10,
      offset: 0,
      pageCount: 0,
      pagination: [],
      block: 'false',
      selectAll: false,
      selectAllHalf: false
    })

    onMounted(() => {
      if (Route.path === '/panel/members_by_all') query()
    })

    // 外部调用这里，这里再调用外部
    const query = () => {
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    }

    // watch(() => [data.selectAll, data.selectAllHalf], ([selectAllNewer, selectAllHalfNewer], [selectAllOlder, selectAllHalfOlder]) => {
    // 监听全选
    watch(() => data.selectAll, (newer, older) => {
      props.list.forEach(item => {
        if (item.level === 'creator' || item.level === 'administrator') return // forEach 是函数不支持使用 continue 跳过，必须使用 return 跳过
        if (newer) {
          item.checkbox = true
        } else {
          item.checkbox = false
        }
      })
    })
    // 监听切换单页显示个数
    watch(() => data.limit, (newer, older) => {
      data.offset = 0
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    })
    // 监听数据变更，计算分页
    watch(() => props.list, (newer, older) => {
      computedPagination()
    })

    const selectOne = () => {
      let count = 0
      let totalCount = props.list.length
      props.list.forEach(item => {
        if (item.checkbox) count++
        if (item.level === 'creator' || item.level === 'administrator') totalCount--
      })
      if (count === totalCount) {
        data.selectAll = true
        data.selectAllHalf = false
      } else if (count === 0) {
        data.selectAll = false
        data.selectAllHalf = false
      } else {
        data.selectAll = false
        data.selectAllHalf = true
      }
    }

    // 计算分页器
    const computedPagination = () => {
      const tmp = []
      let totalPage = 0
      if (props.total % data.limit === 0) {
        totalPage = props.total / data.limit
      } else {
        totalPage = Math.floor(props.total / data.limit) + 1
      }
      for (let i = 1; i <= totalPage; i++) {
        tmp.push(i)
      }
      data.pageCount = tmp.length
      if (tmp.length > 7) {
        if (data.offset < 4) {
          data.pagination = [tmp[0], tmp[1], tmp[2], tmp[3], tmp[4], '...', tmp[tmp.length - 1]]
        }
        if (data.offset >= 4 && data.offset < tmp.length - 4) {
          data.pagination = [tmp[0], '...', tmp[data.offset - 1], tmp[data.offset], tmp[data.offset + 1], '...', tmp[tmp.length - 1]]
        }
        if (data.offset >= tmp.length - 4) {
          data.pagination = [tmp[0], '...', tmp[tmp.length - 5], tmp[tmp.length - 4], tmp[tmp.length - 3], tmp[tmp.length - 2], tmp[tmp.length - 1]]
        }
      } else {
        data.pagination = tmp
      }
    }

    // 切页
    const pagePrev = () => {
      if (data.offset <= 0) return
      data.offset--
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    }
    const page = item => {
      if (isNaN(item)) return
      data.offset = item - 1
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    }
    const pageNext = () => {
      if (data.offset >= data.pageCount - 1) return
      data.offset++
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    }

    // 根据什么查询
    const orderBy = by => {
      data.offset = 0
      if (data.sort === 'desc') {
        data.sort = 'asc'
      } else {
        data.sort = 'desc'
      }
      data.order = by
      context.emit('reload', data.sort, data.order, data.limit, data.offset)
    }

    // 踢人
    const kickMembers = () => {
      const tmp = []
      props.list.forEach(item => {
        if (item.checkbox) tmp.push(item.id)
      })
      if (tmp.length === 0) {
        Dialog({
          content: t('components.membersList.kickMembers.error'),
          btns: [{ text: t('ok') }]
        })
        return
      }
      Dialog({
        content: t('components.membersList.kickMembers.ask', { count: tmp.length }),
        btns: [{ text: t('ok'), click: () => kick(tmp) }, { text: t('cancel') }]
      })
    }

    const kick = ids => {
      Dialog({ type: 'loading' })
      kickMembersApi({
        ids: JSON.stringify(ids),
        block: data.block
      }).then(succ => {
        if (succ.code === 10000) {
          Dialog({
            content: t('components.membersList.kickMembersApi.success', { total: succ.data.total, success: succ.data.success, failed: succ.data.failed }),
            closeable: false,
            btns: [{ text: t('ok'), click: () => { data.offset = 0; context.emit('reload', data.sort, data.order, data.limit, data.offset) } }]
          })
        } else if (succ.code === 10001) {
          Dialog({
            content: t('components.membersList.kickMembersApi.paramError'),
            closeable: true,
            btns: [{ text: t('ok') }]
          })
        }
      })
    }

    const goMember = id => {
      if (!data.record) return

      Store.commit('setMember', { uid: id })
      Router.push({ name: 'Member' })
    }

    return { ...toRefs(data), t, query, selectOne, pagePrev, page, pageNext, orderBy, kickMembers, goMember }
  }
}
</script>

<style lang="scss" scoped>
.members-list {
  width: 100%;
  border-radius: 5px;
  tr {
    height: 40px;
    th {
      p {
        display: table;
      }
      .up::after {
        content: "";
        display: table-cell;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        opacity: 0.4;
        background: no-repeat center center / 100% auto url('../assets/img/sort-up.svg');
      }
      .down::after {
        content: "";
        display: table-cell;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        opacity: 0.4;
        background: no-repeat center center / 100% auto url('../assets/img/sort-down.svg');
      }
    }
    th, td {
      padding: 0 0.5em;
      text-align: center;
      p {
        width: 100%;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .checkbox {
      width: 40px;
      .star {
        i {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0 auto;
          background-size: cover;
          background-position: center center;
        }
        &-blue {
          background-image: url('../assets/img/blue-star.svg');
        }
        &-gray {
          background-image: url('../assets/img/gray-star.svg');
        }
      }
    }
    .total {
      width: 80px;
      cursor: pointer;
    }
    .time {
      width: 170px;
      cursor: pointer;
    }
    .nickname-td {
      display: table;
      table-layout: fixed;
      span {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
      }
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: no-repeat center center / cover gray;
      }
      .name {
        padding: 0 6px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .action-bar {
    .operate {
      display: table;
      width: 100%;
      > div {
        display: table-cell;
        vertical-align: middle;
      }
      .select-all {
        width: 80px;
        padding-left: 4px;
        text-align: left;
        div {
          display: inline-block;
        }
      }
      .pagination {
        > div {
          display: table-cell;
          vertical-align: middle;
        }
        .page {
          a, span, em {
            display: table-cell;
            vertical-align: middle;
          }
          a {
            height: 10px;
            padding: 0 10px;
            border-radius: 3px;
            svg {
              width: 12px;
            }
          }
          a:hover {
            color: #fff;
            background-color: #e54d26;
            svg {
              fill: #fff;
            }
          }
          span {
            padding: 0 5px;
            em {
              padding: 0 2px;
              i {
                color: #fff;
                display: block;
                width: 28px;
                height: 20px;
                font-size: 14px;
                line-height: 20px;
                border-radius: 3px;
                background: #60b7ff;
              }
              i:hover {
                background: rgba(96, 183, 255, 0.6);
              }
            }
          }
        }
        .total {
          span {
            color: gray;
            margin: 0 15px 0 10px;
            font-size: 14px;
            white-space: nowrap;
          }
        }
        .limit {
          select {
            height: 25px;
            padding: 0 2px;
            border-radius: 3px;
            border: 1px solid gray;
          }
        }
      }
      .radio {
        text-align: left;
        div {
          padding-right: 20px;
          display: inline-block;
        }
      }
      .submit {
        width: 7.5em;
        a {
          color: #fff;
          display: block;
          width: 100%;
          height: 33px;
          font-size: 14px;
          line-height: 33px;
          text-align: center;
          border-radius: 5px;
          background: #60b7ff;
        }
      }
    }
  }
}
</style>
